<template>
  <b-button
    class="d-flex align-items-center justify-content-center"
    :variant="button.variant"
    @click="$emit('click')"
  >
    <span :class="button.icon" class="mr-1" />
    <span class="d-none d-md-block d-lg-block">
      {{ button.label }}
    </span>
  </b-button>
</template>

<script>
import { STATUS } from '@/constants'

export default {
  name: 'StatusAction',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    button() {
      return this.value === STATUS.ACTIVE.value
        ? {
            label: 'Desativar',
            icon: 'fas fa-times',
            variant: 'warning'
          }
        : {
            label: 'Ativar',
            icon: 'fas fa-check',
            variant: 'success'
          }
    }
  }
}
</script>
