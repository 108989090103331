<template>
  <div class="d-flex gap-1">
    <b-button
      size="sm"
      class="d-flex align-items-center justify-content-center"
      variant="dark"
      @click="$emit('edit', item)"
    >
      <span class="fas fa-edit mr-1" />
      <span class="d-none d-md-block d-lg-block"> Editar </span>
    </b-button>
    <b-button
      size="sm"
      class="d-flex align-items-center justify-content-center"
      variant="danger"
      @click="$emit('remove', item)"
    >
      <span class="fas fa-trash mr-1" />
      <span class="d-none d-md-block d-lg-block"> Remover</span>
    </b-button>
    <slot />
    <StatusAction :value="item.status" @click="$emit('updateStatus', item)" />
  </div>
</template>

<script>
import StatusAction from '@/components/StatusAction'

export default {
  name: 'BaseListActions',
  components: {
    StatusAction
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
