import { STATUS } from '@/constants'

const withStatusAction = function (update) {
  return {
    methods: {
      async toggleStatus({ id, status }) {
        const isActive = status === STATUS.ACTIVE.value
        const action = isActive ? 'desativar' : 'ativar'

        const result = await this.$bvModal.msgBoxConfirm(
          `Deseja realmente ${action} este registro?`
        )

        if (result) {
          const toggledStatus = isActive
            ? STATUS.INACTIVE.value
            : STATUS.ACTIVE.value

          await update({ id, status: toggledStatus })

          const action =
            status === STATUS.ACTIVE.value ? 'desativado' : 'ativado'

          this.$root.$toast.success(`Registro ${action} com sucesso`)
        }
      }
    }
  }
}

export default withStatusAction
